/*!
 * This file is part of ORY Editor.
 *
 * ORY Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * ORY Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with ORY Editor.  If not, see <http://www.gnu.org/licenses/>.
 *
 * @license LGPL-3.0
 * @copyright 2016-2018 Aeneas Rekkas
 * @author Aeneas Rekkas <aeneas+oss@aeneas.io>
 *
 */

.ory-cell-draggable {
  cursor: move;
  position: relative;
  transition: opacity 200ms ease;
  /* resolves an issue with drag and drop preview in chrome, see https://github.com/ory/editor/issues/180 */
  /* will-change: transform; */
}

.ory-cell-draggable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  cursor: move;
  border: 1px solid rgba(0, 0, 0, 0);
  transition: all ease .4s;
}

.ory-cell-draggable-overlay:hover {
  background: var(--minBlack);
  border: 1px solid var(--lightWhite);
  background-blend-mode: screen;
}

.ory-cell-draggable-overlay-description {
  text-align: center;
  display: block;
  color: rgba(0, 0, 0, .97);
  max-width: 100%;
}

.ory-cell-draggable-overlay-description span {
  opacity: 0;
  text-align: center;
  color: rgba(0, 0, 0, .97);
  max-width: 90%;
  display: inline-block;
  padding: 8px 22px;
  margin: 0 auto;
  border-radius: 0 0 12px 12px;
  text-transform: uppercase;
  font-size: 75%;
  letter-spacing: 0.15em;
  background: rgba(255, 255, 255, 0);
  transition: all ease .4s;
}

.ory-cell-draggable-overlay:hover .ory-cell-draggable-overlay-description span {
  background: rgba(255, 255, 255, .95);
  opacity: 1;
}

.ory-cell-draggable-overlay.ory-cell-draggable-leaf {
  z-index: 3;
}

.ory-cell-draggable-inline-left.ory-cell-draggable-overlay,
.ory-cell-draggable-inline-right.ory-cell-draggable-overlay {
  float: left;
  z-index: 4;
}

.ory-cell-draggable-is-dragging {
  opacity: 0.4;
  outline: none;
}

