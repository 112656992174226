/*!
 * This file is part of ORY Editor.
 *
 * ORY Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * ORY Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with ORY Editor.  If not, see <http://www.gnu.org/licenses/>.
 *
 * @license LGPL-3.0
 * @copyright 2016-2018 Aeneas Rekkas
 * @author Aeneas Rekkas <aeneas+oss@aeneas.io>
 *
 */

.ory-row-has-floating-children {
  display: block !important;
}

.ory-row-is-hovering-this:before {
  content: " ";
  position: absolute;
  background: black;
  display: block;
  background: var(--darkBlack);
  z-index: 2;
}

.ory-row-is-hovering-above:before {
  top: 0;
  height: 4px;
  width: 100%;
}

.ory-row-is-hovering-below:before {
  bottom: 0;
  height: 4px;
  width: 100%;
}

.ory-row-is-hovering-left-of:before {
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
}

.ory-row-is-hovering-right-of:before {
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px;
}

