/*!
 * This file is part of ORY Editor.
 *
 * ORY Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * ORY Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with ORY Editor.  If not, see <http://www.gnu.org/licenses/>.
 *
 * @license LGPL-3.0
 * @copyright 2016-2018 Aeneas Rekkas
 * @author Aeneas Rekkas <aeneas+oss@aeneas.io>
 *
 */

@keyframes oryCellResizeableAnimate {
  0% {
    background-color: var(--minWhite);
    top: 45%;
    bottom: 45%;
  }
  50% {
    background-color: var(--faintWhite);
    top: 0;
    bottom: 0;
  }
  100% {
    background-color: var(--darkWhite);
    top: 45%;
    bottom: 45%;
  }
}

.ory-cell-resizable-inline-right > .react-resizable-handle {
  left: -4px;
}

.ory-cell-resizable.react-resizable > .react-resizable-handle {
  position: absolute;
  z-index: 5;
  width: 4px;
  top: 0;
  bottom: 0;
  right: -2px;
  cursor: e-resize;
  background-color: var(--lightBlack);
  mix-blend-mode: difference;
  transition: all 200ms linear;
}

.ory-cell-resizable.react-resizable > .react-resizable-handle:after {
  content: " ";
  background-color: var(--darkWhite);
  /* width: 30px; */
  /* height: 30px; */
  position: absolute;
  /* z-index: 1000; */
  top: 45%;
  bottom: 45%;
  left: 0;
  right: 0;
  transition: all 200ms linear;
  mix-blend-mode: normal;

  animation-name: oryCellResizeableAnimate;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-duration: 0.8s;
}

.ory-cell-resizable.react-resizable > .react-resizable-handle:hover:after,
.ory-cell-resizable.react-resizable > .react-resizable-handle:active:after {
  background-color: var(--fullWhite);
  top: 0;
  bottom: 0;
}
