.nestable-icon {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
}
.nestable-icon:before {
  content: '-';
  display: inline-block;
  width: 0;
  height: 0;
  overflow: hidden;
}
/* 20x20 */
.icon-plus-gray {
  width: 20px;
  height: 20px;
  background-image: url("./icon-plus-gray.svg");
}
.icon-minus-gray {
  width: 20px;
  height: 20px;
  background-image: url("./icon-minus-gray.svg");
}
