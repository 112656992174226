/*!
 * This file is part of ORY Editor.
 *
 * ORY Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * ORY Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with ORY Editor.  If not, see <http://www.gnu.org/licenses/>.
 *
 * @license LGPL-3.0
 * @copyright 2016-2018 Aeneas Rekkas
 * @author Aeneas Rekkas <aeneas+oss@aeneas.io>
 *
 */

@import "./Draggable/index.css";
@import "./Droppable/index.css";
@import "./Empty/index.css";
@import "./Resizable/index.css";

.ory-cell-inline-left {
  float: left;
}

.ory-cell-inline-right {
  float: right;
}

.ory-cell-has-inline-neighbour {
  float: none;
  width: 100%;
}
.ory-cell-is-draft {
  opacity: 0.3;
  outline: 1px dashed black;
}

.ory-cell-focused {
  box-shadow: 0 0 1000px rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.ory-cell {
  position: relative;
}

.ory-cell-bring-to-front {
  z-index: 1;
}

.ory-cell-resizing-overlay:after {
  content: " ";
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
