/*!
 * This file is part of ORY Editor.
 *
 * ORY Editor is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * ORY Editor is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with ORY Editor.  If not, see <http://www.gnu.org/licenses/>.
 *
 * @license LGPL-3.0
 * @copyright 2016-2018 Aeneas Rekkas
 * @author Aeneas Rekkas <aeneas+oss@aeneas.io>
 *
 */

.ory-cell-droppable {
  position: relative;
}

.ory-cell-droppable-is-over-current:before {
  content: " ";
  display: block;
  position: absolute;
  z-index: 1;
  background: var(--darkBlack);
}

.ory-cell-droppable-is-over-above:before,
.ory-cell-droppable-is-over-below:before {
  border-left: 0;
  border-right: 0;
}
.ory-cell-droppable-is-over-left-of:before,
.ory-cell-droppable-is-over-right-of:before {
  border-top: 0;
  border-bottom: 0;
}

.ory-cell-droppable-is-over-above:before {
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  border-left: 0;
  border-right: 0;
}
.ory-cell-droppable-leaf.ory-cell-droppable-is-over-above:before {
  height: 4px;
}

.ory-cell-droppable-is-over-below:before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
}
.ory-cell-droppable-leaf.ory-cell-droppable-is-over-below:before {
  height: 4px;
}

.ory-cell-droppable-is-over-left-of:before {
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
}
.ory-cell-droppable-leaf.ory-cell-droppable-is-over-left-of:before {
  width: 4px;
}

.ory-cell-droppable-is-over-right-of:before {
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px;
}
.ory-cell-droppable-leaf.ory-cell-droppable-is-over-right-of:before {
  width: 4px;
}

.ory-cell-droppable-is-over-inline-right:before,
.ory-cell-droppable-is-over-inline-left:before {
  top: 0;
  width: 50%;
  height: 36%;
  max-height: 128px;
  background-color: var(--grey300);
  border: 1px solid var(--minBlack);
}

.ory-cell-droppable-is-over-inline-left:before {
  left: 0;
}
.ory-cell-droppable-is-over-inline-right:before {
  right: 0;
}
